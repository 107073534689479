import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import { Segment } from 'components/segment/Segment';
import SEO from '../components/seo/SEO';

// tslint:disable no-default-export
export default () => {
  const intl = useIntl();

  return (
    <>
      <SEO title="404 Not Found"/>

      <Segment>
        <h1>{intl.formatMessage({ id: '404textPart1' })}</h1>
        <h1>{intl.formatMessage({ id: '404textPart2' })}</h1>
      </Segment>
    </>
  );
}
